import React from 'react'
import ProgressiveImage from '../Common/ProgressiveImg'
import Link from 'next/link'
import moment from 'moment'
import Image from 'next/image'

export default function ArticleCard({item, classCss, bordered}){

    return (
        <div className={`${classCss ? classCss : 'h-100'}`}>

            <article className={`h-100 single-blog-post flex-fill ${(bordered) ? 'bordered' : ''}`}>
                
                <div className="post-image"
                    style={{'height':'0','paddingBottom':'54.55%'}}
                
                >
                    <Link prefetch={false} href="/library/[article]" as={`/library/${item.slug}`}>
                        
                        {
                        item.picture ? (
<Image 
                                alt={item.imagemetadata}
                                src={item.picture && item.picture.replace('.jpg','').replace('.png','')}
                                width={512}
                                height={275}
                                layout='responsive'
                                quality='75'
                               
                            /> 
                        ) : (
                            
                                <Image 
                               
                                    alt='Default image placeholder.' 
                                   
                                   width={64}
                                   height={64}
                                    loader={({src, width, quality})=> {return `${src}?w=${width}&q=${quality || 75}`}}  
                                    src={'/static/images/default-pic-small.png'} 
                                ></Image>
                            
                        )
                    }
                            
                        
                    </Link>
                    <div className="date">  
                        <span>{moment(item.updatedAt).format('MMMM Do YYYY')}</span>
                    </div>
                </div>
                <div className="post-content h-100 d-flex flex-column justify-content-between">
                    <div>
                        <div style={{flexWrap:'wrap-reverse'}} className='d-flex align-items-center justify-content-between'>
                            <span className='badge badge-pill badge-primary'>{item.category.name}</span>
                            <small className='text-muted' style={{whiteSpace:'nowrap'}} title={`${item.timeread} minutes timeread`}>
                                <i className='bx bx-time-five'></i>
                                {item.timeread} MIN READ
                            </small>
                        </div>
                        
                        <h3>
                            <Link prefetch={false} href="/library/[article]" as={`/library/${item.slug}`}>
                                {item.name}
                            </Link>
                        </h3>
                        <p className='ellipsis-3-lines mt-2'>{item.metadata}</p>
                    </div>
                    <div className='mt-3'>
                        
                        <Link className="details-btn" prefetch={false} href="/library/[article]" as={`/library/${item.slug}`}>
                            Read Article
                        </Link>
                    </div>
                </div>
            </article>
        </div>
    )
}

export function ArticleCardSlider({item, classCss, bordered}){

    return (
        <div>
<Link prefetch={false} href="/library/[article]" as={`/library/${item.slug}`}>
                        
            <article className={`single-blog-post horizontal no-radius`}>
                
                <div className="post-image"

                >
                    {
                        item.picture ? (
<Image 
                                alt={item.imagemetadata}
                                src={item.picture.replace('.jpg','').replace('.png','')}
                                layout='responsive'
                                width={512}
                                height={275}
                                // layout='responsive'
                                quality='75'
                                
                            /> 
                        ) : (
                            
                                <Image 
                               
                                    alt='Default image placeholder.' 
                                   
                                   width={64}
                                   height={64}
                                    loader={({src, width, quality})=> {return `${src}?w=${width}&q=${quality || 75}`}}  
                                    src={'/static/images/default-pic-small.png'} 
                                ></Image>
                            
                        )
                    }
                            
                            
                       
                   
                    {/* <div className="date">  
                        <span>{moment(item.updatedAt).format('MMMM Do YYYY')}</span>
                    </div> */}
                </div>
                <div className="post-content h-100 d-flex flex-column justify-content-between">
                    <div>
                        
                        <h3>
                           
                                {item.name}
                          
                        </h3>
                        <span className='badge badge-pill badge-primary mt-1'>{item.category.name}</span>
                       
                        <div className='d-flex'>
                            <p className='ellipsis-3-lines'>{item.metadata}</p>
                            
                        </div>
                        
                        <div className='d-flex align-items-center justify-content-between mt-2'>
                            
                            
                            <div className='meta-articles'>
                            <small className='text-dark' style={{whiteSpace: 'nowrap'}}>
                                <i className='bx bx-calendar' style={{marginTop:'1px'}}></i>
                                {moment(item.updatedAt).format('MMM Do YYYY').toUpperCase()}
                            </small>
                            <div className='separator'>&nbsp;|&nbsp;</div>
                            <small className='text-dark' style={{whiteSpace: 'nowrap'}} title={`${item.timeread} minutes timeread`}>
                                <i className='bx bx-time-five' style={{marginTop:'1px'}}></i>
                                {item.timeread} MIN READ
                            </small>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>
            </article>
            
            </Link>
        </div>
    )
}

    