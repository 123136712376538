import React, { Component } from 'react';
import BreadcumbLinks from './BreadcumbLinks';

class PageBanner extends Component {
    render() {
        let { pageTitle, children, homePageUrl, homePageText, activePageText, middlePages, condensedLayout, disableBreadcumb, description, smallFont } = this.props;
        return (
            <div className="page-title-area">
                <div className={`${condensedLayout ?  'container' : 'container-fluid'}`}>
                    <div className="page-title-content">
                        <small>
                        {
                            !disableBreadcumb && <BreadcumbLinks
                                middlePages={middlePages}
                                activePageText={activePageText}
                                homePageUrl={homePageUrl}
                                homePageText={homePageText}
                            />
                        }
                        </small>
                        <h1 style={smallFont ? {fontSize:'1.1rem'} : {}} className={`${!disableBreadcumb ? 'mt-2' : ''} ${description ? 'mb-2' : ''}`}>{pageTitle}</h1>
                        {
                            description && <small>{description}</small>
                        }
                        
                    </div>
                    {
                        children
                    }
                </div>
            </div>
        );
    }
}

export default PageBanner;