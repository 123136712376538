import { useState, useEffect } from 'react';
import Link from 'next/link';
import { articleService } from '../services';
import { SyncLoader } from 'react-spinners';
import Image from 'next/image';
import Footer from '../components/Layouts/Footer';
import Navbar from '../components/Layouts/Navbar';
import PageBanner from '../components/Common/PageBanner';
import BottomTab from '../components/Layouts/BottomTab';
import ArticleCard from '../components/Cards/Article';
import Layout from '../components/_App/Layout';

const Error404 = (props) => {

    const [ blogs, setBlogs ] = useState([])
    const [ loading, setLoading ] = useState(true)

    const getBlogs = async() => {

        try {
            setLoading(true)
            const res = await articleService.getAll('?sample=4')
            setBlogs(res.totalData)
            setLoading(false)
        } catch (er) {
            console.log(er)
        }

    }

    useEffect(() => {
        getBlogs()
    }, [])
    
    
    return (
        
            <Layout
            isAppleMobile = {props.isAppleMobile}
            seo={{
                title:`Not Found | Weedzly`,
            }}
            >
            <Navbar />
            <PageBanner 
                pageTitle="404 Not Found" 
                homePageUrl="/" 
                homePageText="Home" 
                activePageText="404 Not Found" 
            /> 
            <section className="error-area ptb-70">
                <div className="container-fluid">
                    <div className="error-content">
                        
                        <div className='d-flex'>
                            <Image 
                                alt='Not found image.' 
                                width={'520'}
                                height={'290'}
                                layout='intrinsic'
                                loader={({src, width, quality})=> {return `${src}?w=${width}&q=${quality || 75}`}}  
                                src={'/static/images/error-404.png'} 
                            ></Image>
                        </div>
                        <h3>Page Not Found</h3>
                        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                        <Link className="default-btn" prefetch={false} href="/">
                            Go to Home
                        </Link>
                    </div>
                </div>
                <div className='container'>
                    <br/>
                    {
                        loading ? (
                            <div className='d-flex align-items-center justify-content-center'>
                                <div><SyncLoader color='purple' fullScreen={true} /></div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className='d-flex align-items-center justify-content-center w-100'>
                                    <h4><center>Read these articles</center></h4>
                                </div>
                                <br/>
                                <div className="col-lg-12 col-md-12">
                                    <div className="cards-grid-list-with-sidebar">
                                        {
                                            blogs.length > 0 && blogs.map(x => <ArticleCard item={x} />)
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>
            <BottomTab />
            <Footer />
        </Layout>
      
    );
    
}

export default Error404;
